import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

const mainImage = require("images/cottage/IMG-20240713-WA0010.jpg");
const image1 = require("images/cottage/IMG-20240713-WA0011.jpg");
const image2 = require("images/cottage/IMG-20240713-WA0012.jpg");
const image3 = require("images/cottage/IMG-20240713-WA0013.jpg");
const image4 = require("images/cottage/IMG-20240713-WA0014.jpg");
const image5 = require("images/cottage/IMG-20240713-WA0015.jpg");
const image6 = require("images/cottage/IMG-20240713-WA0016.jpg");
const image7 = require("images/cottage/IMG-20240713-WA0017.jpg");
const image8 = require("images/cottage/IMG-20240713-WA0018.jpg");
const image9 = require("images/cottage/IMG-20240713-WA0019.jpg");
const image10 = require("images/cottage/IMG-20240713-WA0020.jpg");
const image11 = require("images/cottage/IMG-20240713-WA0021.jpg");
const image12 = require("images/cottage/IMG-20240713-WA0022.jpg");
const image13 = require("images/cottage/IMG-20240713-WA0023.jpg");
const image14 = require("images/cottage/IMG-20240713-WA0024.jpg");
const image15 = require("images/cottage/IMG-20240713-WA0025.jpg");
const image16 = require("images/cottage/IMG-20240713-WA0026.jpg");
const image17 = require("images/cottage/IMG-20240713-WA0027.jpg");
const image18 = require("images/cottage/IMG-20240713-WA0027.jpg");
const image19 = require("images/cottage/IMG-20240713-WA0027.jpg");
const image20 = require("images/cottage/IMG-20240713-WA0027.jpg");
const image21 = require("images/cottage/IMG-20240713-WA0027.jpg");
const image22 = require("images/cottage/IMG-20240713-WA0027.jpg");
const image23 = require("images/cottage/IMG-20240713-WA0027.jpg");

export const PHOTOS: string[] = [
  mainImage,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
];

export const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

export const imageGallery: ListingGalleryImage[] = [...PHOTOS].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);
