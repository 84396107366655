import { DateRage } from "components/HeroSearchForm/type";

const converSelectedDateToString = ([startDate, endDate]: DateRage) => {
  const dateString =
  startDate && startDate?.toLocaleDateString("pt-BR", {
    day: "2-digit",
  }) +
    "  " + startDate?.toLocaleDateString("pt-BR", {
      month: "short",
    }).charAt(0).toUpperCase() +
    startDate?.toLocaleDateString("pt-BR", {
      month: 'short',
    }).slice(1).replace('.', ' ')  +
    (endDate ? " - " + endDate?.toLocaleDateString("pt-BR", {
      day: "2-digit",
    }) +
      "  " + endDate?.toLocaleDateString("pt-BR", {
        month: "short",
      }).charAt(0).toUpperCase() +
      endDate?.toLocaleDateString("pt-BR", {
        month: 'short',
      }).slice(1).replace('.', ' ')
      : "");
  return dateString;
};

export default converSelectedDateToString;

