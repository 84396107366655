import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          Benefícios
        </span>
        <h2 className="font-semibold text-4xl mt-5">Cidades emergentes </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="Anúncio" />
            <span className="block text-xl font-semibold">
              Publicidade econômica
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Com um anúncio acessível, você pode anunciar seu aluguel com custos que no fim rendem mais no seu bolso
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Exposição " />
            <span className="block text-xl font-semibold">
              Alcance milhões com Chaaales
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Milhões de pessoas procuram lugares únicos para ficar em todo o mundo
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Segura" />
            <span className="block text-xl font-semibold">
              Segura e simples
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Um anúncio da Holiday Lettings oferece uma maneira fácil e segura de fazer reservas e pagamentos on-line
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
