
const mainImage = require('../../images/cottage/IMG-20240713-WA0010.jpg');

const image1 = require('../../images/cottage/IMG-20240713-WA0011.jpg');

const image2 = require('../../images/cottage/IMG-20240713-WA0012.jpg');

const image3 = require('../../images/cottage/IMG-20240713-WA0013.jpg');

const image4 = require('../../images/cottage/IMG-20240713-WA0014.jpg');

 const  listingStay = [
  {
    "id": "id1",
    "authorId": 10,
    "date": "May 20, 2021",
    "href": "/listing-stay-detail",
    "listingCategoryId": 17,
    "title": "Chale Pico da Montanha",
    "featuredImage": mainImage,
    "galleryImgs": [
      image1,
      image2,
      image3,
      image4
    ],
    "commentCount": 70,
    "viewCount": 602,
    "like": false,
    "address": "Sapucaí-Mirim, Minas Gerais, Brasil",
    "reviewStart": 4.8,
    "reviewCount": 28,
    "price": "$26",
    "maxGuests": 6,
    "bedrooms": 10,
    "bathrooms": 3,
    "saleOff": "-10% hoje",
    "isAds": null,
    "map": {
      "lat": 55.2094559,
      "lng": 61.5594641
    }
  }
]

export default listingStay;